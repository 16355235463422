// Show/hide functionality for multi-function buttons
const multiFunctionButtons = document.querySelectorAll(".multi-function-button");

multiFunctionButtons.forEach(multiFunctionButton => {
	var trigger = multiFunctionButton.querySelector(".multi-function-button__trigger");

	trigger.addEventListener("click", () => {
		multiFunctionButton.classList.toggle("multi-function-button--open");
	});

	var listItems = multiFunctionButton.querySelectorAll(".multi-function-button__list-item");

	// On click of a listen item, remove the open class
	if(listItems) {
		listItems.forEach(listItem => {
			listItem.addEventListener("click", (e) => {
				multiFunctionButton.classList.remove("multi-function-button--open");
			})
		});
	}
});


// Functions that multi-function buttons can perform
window.multiFunctionButton = {

	/**
	 * Triggers the tab at index given the id of a tabset
	 * If id is not given, it will use the closest tabset
	 * If index is not given, it will use the index of the clicked element
	 */
	triggerTab: (e, id, index) => {
		e.preventDefault();

		let tab;

		if(id) {
			tab = document.getElementById(id);
		} else {
			tab = e.currentTarget.closest(".tabs");
		}

		if(!index) {
			index = [...e.currentTarget.parentNode.children].findIndex(c => c == e.currentTarget);
		}

		// Set the muiltifunction buttons text to the one clicked
		var multiFunctionButton = e.currentTarget.closest(".multi-function-button");
		var buttonTexts = multiFunctionButton.querySelectorAll(".multi-function-button__text");
		// As we have an open and closed version of the button text, we set it for both...
		buttonTexts.forEach(buttonText => {
			buttonText.innerHTML = e.currentTarget.textContent;
		})

		var tabTriggersParent = tab.querySelector(".tabs__triggers");
		var tabTriggers = [].slice.call(tabTriggersParent.querySelectorAll(".tabs__trigger"));

		var tabContentsParent = tab.querySelector(".tabs__content");
		var tabContents = [].slice.call(tabContentsParent.querySelectorAll(".tab"));

		// Filter out tab contents so we only get direct children
		tabContents = tabContents.filter((tabContent, i) => { return tabContent.parentNode == tabContentsParent });

		// On click of any tab trigger...
		tabTriggers.forEach((tabTrigger, i) => {
			// Remove classes from other tab triggers and contents so they're not active
			tabTriggers.forEach((tabTrigger) => { tabTrigger.classList.remove("tabs__trigger--active"); });
			tabContents.forEach((tabContent) => { tabContent.classList.remove("tab--active"); });

			// Make the trigger we clicked, and its corresponding contents active
			tabTriggers[index].classList.add("tabs__trigger--active");
			tabContents[index].classList.add("tab--active");
		});
		
	}

}