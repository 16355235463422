import ProgressBar from 'progressbar.js'

const progressBars = document.querySelectorAll('.progress-bar')

if(progressBars.length > 0) {
	progressBars.forEach(progressBar => {
		const bar = new ProgressBar.Line(progressBar, {
			strokeWidth: 1,
			easing: 'easeInOut',
			duration: 1400,
			color: '#95C11F',
			trailColor: 'rgb(229, 229, 231)',
			trailWidth: 1,
			svgStyle: {
				borderRadius: '5px',
				height: '.75rem',
				width: '100%',
			},
			autoStyleContainer: false,
			text: {
				className: 'progress-bar__label',
				value: '0%',
				style: {
					marginBottom: '-2rem',
					textAlign: 'center',
					transform: 'none',
					position: 'static',
					color: '#929291'
				}
			},
			step: function(state, line) {
				let value = Math.round(line.value() * 100)+'%'

				if (value === 0) {
					line.setText('')
				} else {
					line.setText(value)
				}
			}
		})

		bar.animate(0.8)
	})
}
