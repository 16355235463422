const scrollToTriggers = document.querySelectorAll('[data-scroll-to]')

if(scrollToTriggers.length > 0) {
	scrollToTriggers.forEach(trigger => {
		trigger.addEventListener('click', e => {
			e.preventDefault()

			const target = document.getElementById(trigger.dataset.scrollTo).offsetTop - 60

			window.scrollTo(0, target)
		})
	})
}
