const filtersElements = document.querySelectorAll('.filters')
const filtersActiveClass = 'filters--open'

if(filtersElements.length > 0) {
	filtersElements.forEach(filter => {
		const filterTrigger = filter.querySelector('.filters__text')

		if(filterTrigger) {
			filterTrigger.addEventListener('click', () => {
				filter.classList.toggle(filtersActiveClass)
			})

			document.addEventListener('click', function(e) {
				const isClickInside = filter.contains(e.target)
		
				if (!isClickInside && filter.classList.contains(filtersActiveClass)) {
					filter.classList.toggle(filtersActiveClass)
				}
			})
		}
	})
}
