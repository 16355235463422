const tables = document.querySelectorAll(".table");

tables.forEach(table => {
	const readMores = table.querySelectorAll(".table__read-more");

	readMores.forEach(readMore => {
		if(readMore.getAttribute("href") !== "#") return;

		var nextTr = readMore.closest("tr").nextElementSibling;

		if(nextTr) {
			nextTr.classList.add("hide");

			readMore.addEventListener("click", e => {
				e.preventDefault();
				nextTr.classList.toggle("hide");
				readMoreTexts = readMore.querySelectorAll(".table__read-more-text");

				if(readMoreTexts) {
					readMoreTexts.forEach(readMoreText => { readMoreText.classList.toggle("table__read-more-text--hide"); });
				}
			});
		}
	})
})