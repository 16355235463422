const windowTriggers = document.querySelectorAll('[data-open-window]')
const windows = document.querySelectorAll('.window')
const windowOpenClass = 'window--open'

if(windowTriggers.length > 0) {
	windowTriggers.forEach(trigger => {
		const target = document.getElementById(trigger.dataset.openWindow)

		trigger.addEventListener('click', e => {
			e.preventDefault()

			target.classList.toggle(windowOpenClass)
		})
	})
}
