const toggleElements = document.querySelectorAll('.toggle')

if(toggleElements.length > 0) {
	toggleElements.forEach(toggle => {
		const toggleOptions = toggle.querySelectorAll('.toggle__option')
		const toggleActiveClass = ('toggle__option--active')

		toggleOptions.forEach(option => {
			option.addEventListener('click', (e) => {
				e.preventDefault()

				const optionShow = document.querySelector(option.dataset.toggleShow)
				const optionHide = document.querySelector(option.dataset.toggleHide)
				const currentActiveClass = toggle.querySelector(`.${toggleActiveClass}`)

				console.log(optionShow, optionHide)

				if(currentActiveClass) {
					currentActiveClass.classList.remove(toggleActiveClass)
				}

				option.classList.add(toggleActiveClass)

				optionShow.classList.add('u-show')
				optionShow.classList.remove('u-hide')
				optionHide.classList.add('u-hide')
				optionHide.classList.remove('u-show')
			})
		})
	})
}
