const switches = document.querySelectorAll('.switch')
const switchActiveClass = 'switch--active'

if(switches.length > 0) {
	switches.forEach(sw => {
		sw.addEventListener('click', e => {
			e.preventDefault()

			sw.classList.toggle(switchActiveClass)
		})
	})
}

