const sideNavs = document.querySelectorAll('.side-nav--expandable')

if(sideNavs.length > 0) {
	sideNavs.forEach(nav => {
		const trigger = nav.querySelector('.side-nav__expand')
		const initialItems = nav.dataset.initialItems ? parseInt(nav.dataset.initialItems) : 5
		const listItems = nav.querySelectorAll('.side-nav__list > li')
		const expandText = trigger.innerHTML
		const collapseText = trigger.dataset.collapseText ? trigger.dataset.collapseText : 'Show less'
		let state = 'collapsed'

		if(initialItems > listItems.length) {
			nav.classList.remove('side-nav--expandable')
			trigger.style.display = 'none'
		}

		// Only show the initial items
		listItems.forEach((li, i) => {
			if((i + 1) > initialItems) {
				li.style.display = 'none'
			}
		})

		trigger.addEventListener('click', (e) => {
			e.preventDefault()

			trigger.classList.toggle('side-nav__expand--close')

			if(state == 'collapsed') {
				listItems.forEach((li, i) => {
					li.style.display = 'block'
				})

				state = 'expanded'
				trigger.innerHTML = collapseText
			} else {
				listItems.forEach((li, i) => {
					if((i + 1) > initialItems) {
						li.style.display = 'none'
					} else {
						li.style.display = 'block'
					}
				})

				state = 'collapsed'
				trigger.innerHTML = expandText
			}
		})
	})
}
