const copyTriggers = document.querySelectorAll('[data-copy-value]')

if(copyTriggers.length > 0) {
	copyTriggers.forEach(trigger => {
		trigger.addEventListener('click', (e) => {
			e.preventDefault()

			copy(trigger)
		})
	})
}

function copy(el) {
	const copyText = document.getElementById(el.dataset.copyValue)

	copyText.select()
	document.execCommand('Copy')
}
