window.chartOptions = {
	elements: {
		arc: {
			borderWidth: 0
		}
	},
	legend: {
		display: false
	},
	tooltips: {
		backgroundColor: '#fff'
	}
}
