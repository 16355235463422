import { breakpoints } from '../utils/breakpoints'

const teamComponents = document.querySelectorAll('.team')

if(teamComponents) {
	teamComponents.forEach(teamComponent => {
		const navItems = teamComponent.querySelectorAll('.team__nav-item')
		const items = teamComponent.querySelectorAll('.team__item')
		const itemActiveClass = 'team__item--active'
		const navItemActiveClass = 'team__nav-item--active'

		navItems.forEach(navItem => {
			navItem.addEventListener('click', (e) => {
				const navItemTarget = navItem.dataset.teamMemberTarget

				e.preventDefault()

				navItems.forEach(navItem => {
					if(navItem.classList.contains(navItemActiveClass)) {
						navItem.classList.remove(navItemActiveClass)
					}
				})
				
				items.forEach(item => {
					if(item.classList.contains(itemActiveClass)) {
						item.classList.remove(itemActiveClass)
					}
				})

				document.querySelector(`[data-team-member-id="${navItemTarget}"]`).classList.add(itemActiveClass)
				navItem.classList.add(navItemActiveClass)

				if(window.innerWidth < breakpoints.medium) {
					scrollTo(document.documentElement, document.querySelector(`[data-team-member-id="${navItemTarget}"]`).offsetTop, 600)
				}
			})
		})
	})
}

function scrollTo(element, to, duration) {
	if (duration <= 0) return
	const difference = to - element.scrollTop
	const perTick = difference / duration * 10

	setTimeout(function() {
		element.scrollTop = element.scrollTop + perTick
		if (element.scrollTop === to) return
		scrollTo(element, to, duration - 10)
	}, 10)
}
