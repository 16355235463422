const basket = document.querySelector('.basket')
const basketTrigger = document.querySelector('.basket__trigger')

if(basket && basketTrigger) {
	basketTrigger.addEventListener('click', (e) => {
		e.preventDefault()

		basket.classList.toggle('basket--open')
	})
}
